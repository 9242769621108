import { FC } from "react";
import { Container } from "react-bootstrap";
import { TransRouterMotionSection } from "../../trans-router/TransRouterMotionSection";

import "./About.scss";

const Version = process.env.REACT_APP_VERSION;

const About: FC = () => {
  return (
    <TransRouterMotionSection className="About">
      <Container>
        <div className="text-center">
          <h3 className="mb-4">CigerMarketResearch</h3>
          <img
            src="/logo512.png"
            className="img-fluid logo mb-3"
            alt="ロゴ"
          />
          <p>version: {Version}</p>
        </div>
      </Container>
    </TransRouterMotionSection>
  );
}

export default About;