import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight, faCheck, faPlus, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Container, FormCheck, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { CurrencyCode } from "../../def";
import { useHttpClient } from "../../http-client/HttpClient";
import { OverlaySortable } from "../../overlay-sortable";
import { TransRouterMotionSection } from "../../trans-router/TransRouterMotionSection";
import { useUser } from "../../user/User";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { TrendSet } from "./ModifySet";
import { BadgeGoodTimeToBuy, BadgeIsSetting, ResearchList, ResearchRef, getCurrencySymbol, getRate, useExchangeRates, useGlobalSetting, useResearchRootContext } from "./ResearchList";

const ModifySetDetail: FC = () => {
  const { source, id } = useParams<{ source: string; id: string; }>();
  const { sort } = useResearchRootContext();
  const { httpClient } = useHttpClient();
  const { user } = useUser();
  const [trendSetName, setTrendSetName] = useState("");
  const [products, setProducts] = useState<{ id: string; name: string; currencyCode: CurrencyCode; }[]>([]);
  const testRef = useRef<ResearchRef>();
  const { exchangeRates } = useExchangeRates();
  const { priceCoefficient, bankFee } = useGlobalSetting();
  const [loaded, setLoaded] = useState(false);
  const [mode, setMode] = useState<"ADD" | "NON_ADD">("NON_ADD");
  const [selectedProducts, setSelectedProducts] = useState<{ name: string; currencyCode: CurrencyCode; }[]>([]);
  const [updateCnt, setUpdateCnt] = useState(0);
  const [confirmDeleteTarget, setConfirmDeleteTarget] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const { data } = await httpClient.get<TrendSet>(`/api/v2/trend-sets/${user?.payload.id}/${source}/${id}`);
      setTrendSetName(data.name);
      setProducts(data.products.map(product => ({ id: product.name, ...product })));
      setLoaded(true);
    })().catch(err => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, id, updateCnt]);

  const updateTrendSets = async (products: { name: string; currencyCode: CurrencyCode; }[]) => {
    await httpClient.put<TrendSet>(`/api/v2/trend-sets/${user?.payload.id}/${source}/${id}`, {
      products
    });
    setUpdateCnt(cnt => cnt + 1);
  }

  if (!source) {
    return null;
  }

  return (
    <>
      <TransRouterMotionSection className="ModifySet" onBack={() => "/modify-set"}>
        <Container>
          <h2 className="h5 mb-2">価格推移セット編集<FontAwesomeIcon icon={faAngleRight} className="ms-1 me-1" />商品選択</h2>
          <div className="d-flex justify-content-end mb-3">
            <div className="text-end">
              <h3 className="h6 mb-1">{source}</h3>
              <h3 className="h6 mb-0">{trendSetName}</h3>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant="secondary"
              size="sm"
              disabled={products.length >= 10 || mode !== "NON_ADD"}
              onClick={() => {
                setSelectedProducts([]);
                setMode("ADD");
              }}
              className="btn-icon"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>

          {
            (() => {
              switch (mode) {
                case "NON_ADD":
                  if (!loaded) {
                    return (
                      <div className="text-center"><Spinner animation="border" variant="secondary" /></div>
                    );
                  }
                  if (products.length === 0) {
                    return (
                      <div className="text-center text-muted">右上の<FontAwesomeIcon icon={faPlus} className="ms-1 me-1" />ボタンで商品を追加してください。</div>
                    );
                  }
                  return (
                    <OverlaySortable
                      defaultItems={products}
                      onChange={async (item) => {
                        await updateTrendSets(item.map(({ name, currencyCode }) => ({ name, currencyCode })));
                      }}
                      SortableItem={({ item }) => (
                        <div className="d-flex justify-content-between gap-4">
                          <div className="flex-grow-1 align-self-center text-break position-relative">
                            {item.name}
                          </div>
                          <div className="align-self-center d-flex">
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => {
                                setConfirmDeleteTarget(item.name);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        </div>
                      )}
                    />
                  );
                default:
                  return (
                    <ResearchList source={source} sort={sort} ref={testRef} Children={({ price, productPriceSettings }) => {
                      return (<>
                        <div className="d-flex justify-content-between align-items-start flex-column flex-sm-row flex-grow-1">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{price.name}</div>
                            <div>
                              <BadgeIsSetting price={price} productPriceSettings={productPriceSettings} />
                              <BadgeGoodTimeToBuy price={price} productPriceSettings={productPriceSettings} exchangeRates={exchangeRates} priceCoefficient={priceCoefficient} bankFee={bankFee} />
                            </div>
                            <div><small><FontAwesomeIcon icon={faClock} className="me-1" />{moment(price.date).format("YYYY/MM/DD HH:mm")}</small></div>
                          </div>
                          <div className="h-100 ms-2 d-flex justify-content-center align-items-center flex-row flex-sm-column flex-md-row text-nowrap text-end">
                            <div>{getCurrencySymbol("JPY")} {(Math.floor(price.price * (getRate(price.currencyCode + "JPY", exchangeRates) || 0) * priceCoefficient * bankFee)).toLocaleString()}</div>
                            <div className="ms-2 d-sm-none d-md-block"></div>
                            <div>({getCurrencySymbol(price.currencyCode)} {(price.price).toLocaleString()})</div>
                          </div>
                        </div>
                        <div className="ms-3 align-self-center">
                          <FormCheck
                            checked={products.map(({ name }) => name).includes(price.name) || selectedProducts.map(({ name }) => name).includes(price.name)}
                            onChange={(_e) => {
                              setSelectedProducts(products => {
                                const newProducts = [...products];
                                if (selectedProducts.map(({ name }) => name).includes(price.name)) {
                                  return newProducts.filter(product => product.name !== price.name);
                                }
                                newProducts.push({ name: price.name, currencyCode: price.currencyCode });
                                return newProducts;
                              })
                            }}
                            disabled={products.map(({ name }) => name).includes(price.name) || (!selectedProducts.map(({ name }) => name).includes(price.name) && selectedProducts.length + products.length >= 10)}
                          />
                        </div>
                      </>);
                    }} />
                  );
              }
            })()
          }
        </Container>
      </TransRouterMotionSection>
      {
        mode === "ADD" && (
          <footer className="footer fixed-bottom bg-light pt-2 pb-2">
            <div className="container d-flex justify-content-between gap-2">
              <div className="align-self-center flex-grow-1 text-end">
                {(selectedProducts.length + products.length >= 10) && <span className="small text-danger">{10 - products.length}つ(最大10)までしか選択できません</span>}
              </div>
              <div className="align-self-center d-flex gap-1">
                <Button size="sm" variant="secondary" onClick={() => {
                  setMode("NON_ADD");
                }} className="btn-icon"><FontAwesomeIcon icon={faXmark} /></Button>
                <Button size="sm" variant="secondary" onClick={async () => {
                  await updateTrendSets([...products, ...selectedProducts]);
                  setLoaded(false);
                  setMode("NON_ADD");
                }} className="btn-icon"><FontAwesomeIcon icon={faCheck} /></Button>
              </div>
            </div>
          </footer>
        )
      }
      <ConfirmDeleteModal showConfirmModal={!!confirmDeleteTarget} handleClose={() => { setConfirmDeleteTarget(undefined); }} handleDone={() => {
        (async () => {
          await updateTrendSets(products.filter(({ name }) => name !== confirmDeleteTarget).map(({ name, currencyCode }) => ({ name, currencyCode })));
          setConfirmDeleteTarget(undefined);
        })().catch(err => {
          console.error(err);
        });
      }} />
    </>
  );
}
export default ModifySetDetail;