import { AnimatePresence } from "framer-motion";
import { Dispatch, FC, Fragment, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useMatches, useOutlet } from "react-router-dom";

import { useTransRouterNavigate } from "./TransRouterNavigate";

const TransRouterContext = createContext<{
  setTransition: Dispatch<SetStateAction<boolean>>;
  isTransition: boolean;
  setOnBack: Dispatch<SetStateAction<(() => string | Promise<string>) | undefined>>;
}>({
  setTransition: (_) => { },
  isTransition: false,
  setOnBack: (_) => { }
});

export function useTransRouterContext() {
  return useContext(TransRouterContext);
}

const TransRouterRootInner: FC<{
  onBack?: () => string | Promise<string>;
  Header: FC<{
    onBack?: (() => void) | undefined;
  }>;
}> = ({ onBack, Header }) => {
  const matches = useMatches();
  const outlet = useOutlet();
  const { navigateIndent } = useTransRouterNavigate();
  return (
    <>
      <AnimatePresence>
        <Fragment key={onBack ? "back" : "non-back"}>
          {onBack ? (
            <Header onBack={() => {
              (async () => {
                const ret = onBack();
                navigateIndent(ret instanceof Promise ? await ret : ret);
              })().catch(err => {
                console.error(err);
              });
            }} />
          ) : (
            <Header />
          )}
        </Fragment>
      </AnimatePresence>
      <main className="TransRouter__RootMain">
        <AnimatePresence>
          <Fragment key={matches[matches.length - 1].pathname}>{outlet}</Fragment>
        </AnimatePresence>
      </main>
    </>
  );
};

export const TransRouterRoot: FC<{
  Header: FC<{
    onBack?: (() => void) | undefined;
  }>;
}> = ({ Header }) => {
  const [onBack, setOnBack] = useState<(() => string | Promise<string>) | undefined>();
  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (isTransition) {
      document.getElementById("root")?.classList.add("TransRouter__OverflowHidden");
    } else {
      document.getElementById("root")?.classList.remove("TransRouter__OverflowHidden");
    }
  }, [isTransition]);

  useEffect(() => {
    setTransition(true);
  }, []);

  return (
    <TransRouterContext.Provider value={{ setOnBack, setTransition, isTransition }} >
      <TransRouterRootInner onBack={onBack} Header={Header} />
    </TransRouterContext.Provider>
  );
};