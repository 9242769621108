import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";

import { useTransRouterContext } from "./TransRouterRoot";

export function useTransRouterNavigate() {
  const navitate = useNavigate();
  const { pathname } = useLocation();
  const { setTransition } = useTransRouterContext();

  return {
    navigate: (to: To, options?: NavigateOptions | undefined) => {
      if (to.toString() === pathname) {
        return;
      }
      setTransition(true);
      // setTimeout(() => {
      navitate(to, options);
      // }, 200);
    },
    navigateIndent: (to: To, options?: NavigateOptions | undefined) => {
      if (to.toString() === pathname) {
        return;
      }
      setTransition(true);
      // setTimeout(() => {
      const newOptions = { ...options };
      newOptions.state = options?.state ? { previousPath: pathname, ...options.state } : { previousPath: pathname };
      navitate(to, newOptions);
      // }, 200);
    }
  }
}