import {
  DraggableAttributes,
  DraggableSyntheticListeners,
  UniqueIdentifier
} from "@dnd-kit/core";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FC } from "react";

import styles from "./OverlaySortableSource.module.scss";

export type OverlaySortableSourceProps<T extends { id: UniqueIdentifier }> = {
  item: T;
  handlerProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
  className?: string;
  SortableItem: FC<{ item: T }>
};

export const OverlaySortableSource = <T extends { id: UniqueIdentifier }>({
  item,
  handlerProps,
  className,
  SortableItem
}: OverlaySortableSourceProps<T>) => {
  return (
    <div className={clsx(styles.Source, className)}>
      <div
        ref={handlerProps?.ref}
        style={{
          cursor: handlerProps ? "grab" : "grabbing"
        }}
        {...handlerProps?.attributes}
        {...handlerProps?.listeners}
        className="p-1 align-self-center"
      >
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
      <div className={clsx(styles.Source__content, "align-self-center")}>{<SortableItem item={item} />}</div>
    </div>
  );
};
