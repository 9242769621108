import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { clsx } from "clsx";
import { FC } from "react";

import { OverlaySortableSource } from "./OverlaySortableSource";

import { UniqueIdentifier } from "@dnd-kit/core";
import styles from "./OverlaySortableItem.module.scss";

export type OverlaySortableItemProps<T extends { id: UniqueIdentifier }> = {
  item: T;
  SortableItem: FC<{ item: T }>
};

export const OverlaySortableItem = <T extends { id: UniqueIdentifier }>({
  item,
  SortableItem
}: OverlaySortableItemProps<T>) => {
  const {
    isOver,
    activeIndex,
    overIndex,
    isDragging,
    isSorting,
    // DOM全体に設定するプロパティ
    setNodeRef,
    transform,
    transition,
    // つまみ部分に設定するプロパティ
    setActivatorNodeRef,
    attributes,
    listeners
  } = useSortable({
    id: item.id,
    // animateLayoutChanges: ({ isSorting }) => !isSorting
  });

  const canTransform = !isSorting;

  /** どっち向きに並び変わるか */
  const sortDirection =
    activeIndex > overIndex
      ? "before"
      : activeIndex < overIndex
        ? "after"
        : null;

  /** 挿入先を表示するか */
  const isShowIndicator = isOver && sortDirection != null;

  const opacity = 0.3;

  return (
    <div
      ref={setNodeRef}
      className={clsx(styles.Item, {
        [styles._active]: isShowIndicator,
        [styles._before]: sortDirection === "before",
        [styles._after]: sortDirection === "after",
      }, "list-group-item p-0")}
      style={{
        opacity: isDragging ? opacity : undefined,
        transform: canTransform ? CSS.Transform.toString(transform) : undefined,
        transition
      }}
    >
      <OverlaySortableSource
        item={item}
        handlerProps={{
          ref: setActivatorNodeRef,
          attributes,
          listeners
        }}
        SortableItem={SortableItem}
      />
    </div>
  );
};
