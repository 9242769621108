export const sourcesDef = ["cigar-shop-world", "cohcigars", "i-havanas", "finest-cuban-cigars", "cigar-direct", "cigars-of-cuba", "next-cigar"] as const;
export type Source = typeof sourcesDef[number];

export const sources: readonly { label: string; source: Source }[] = [
  { label: "Cigar Shop World", source: "cigar-shop-world" },
  { label: "COH Cigars", source: "cohcigars" },
  { label: "iHavanas", source: "i-havanas" },
  { label: "Finest Cuban Cigars", source: "finest-cuban-cigars" },
  { label: "Cigar Direct", source: "cigar-direct" },
  { label: "Cigars of Cuba", source: "cigars-of-cuba" },
  { label: "Next Cigar", source: "next-cigar" }
] as const;

export const sortDef = ["PriceAsc", "PriceDesc", "NameAsc", "NameDesc"] as const;
export type Sort = typeof sortDef[number];

export type CurrencyCode = "JPY" | "EUR" | "USD";