import axios from "axios";
import { FC, ReactNode, useEffect, useState } from "react";

import { useUser } from "../user/User";
import { redirectWithFunc } from "../utils/redirect";

const RouteAuthGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    (async () => {
      if (!user) {
        redirectWithFunc(async () => {
          const { data: { gotoUrl } } = await axios.get<{ gotoUrl: string; }>("/auth/authorize");
          return gotoUrl;
        });
        return;
      }
      setShowChildren(true);
    })().catch(err => {
      console.error(err);
    })
  }, [user]);

  if (!showChildren) {
    return null;
  }

  return <>{children}</>;
}

export default RouteAuthGuard;