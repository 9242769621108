import { FC } from "react";
import { Form } from "react-bootstrap";
import { Source, sources } from "../def";

export const SourceSelect: FC<{ value: string; onChange: (value: Source) => void; disabled?: boolean; className?: string; }> = ({ value, onChange, disabled, className }) => {
  return (
    <Form.Select aria-label="source select" size="sm" disabled={disabled} className={className} value={value} onChange={async (e) => {
      onChange(e.target.value as Source);
    }}>
      {sources.map(source => (<option key={source.source} value={source.source}>{source.label}</option>))}
    </Form.Select>
  );
}
export default SourceSelect;