import axios, { InternalAxiosRequestConfig } from "axios";
import { FC, ReactNode, createContext, useContext } from "react";
import { Token, UserContext, getUser, useUser } from "../user/User";
import { redirectWithFunc } from "../utils/redirect";

const Offset = 300000; // 5min

const HttpClientContext = createContext({ httpClient: axios.create() });

export function useHttpClient() {
  return useContext(HttpClientContext);
}

export const HttpClientInner: FC<{ children?: ReactNode }> = ({ children }) => {
  const { setUser } = useUser();

  const httpClient = axios.create();
  httpClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
      return config;
    }
    if (!config.url?.startsWith("/api")) {
      return config;
    }
    const user = getUser();
    if (!user) {
      return config;
    }
    const now = Date.now();
    let cpUser = { ...user };
    // ヘッダにアクセストークンを埋める
    if (cpUser.expireDate.getTime() - now - Offset < 0) {
      if (cpUser.refreshTokenExpireDate.getTime() - now - Offset < 0) {
        return config;
      }
      try {
        cpUser = await getRefreshToken(cpUser.refreshToken);
        setUser(cpUser);
      } catch {
        return config;
      }
    }
    config.headers.Authorization = `Bearer ${cpUser.token}`
    return config;
  });
  httpClient.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    if (error.response.status === 401) {
      redirectWithFunc(async () => {
        const { data: { gotoUrl } } = await axios.get<{ gotoUrl: string; }>("/auth/authorize");
        return gotoUrl;
      });
      return;
    }
    throw error;
  })
  return (
    <HttpClientContext.Provider value={{ httpClient }}>
      {children}
    </HttpClientContext.Provider>
  );
}

let isRunning = false;
let resolves: ((value: Token | PromiseLike<Token>) => void)[] = [];

async function getRefreshToken(refreshToken: string) {
  if (isRunning) {
    return new Promise<Token>(resolve => {
      resolves.push(resolve);
    });
  }
  isRunning = true;
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);
  const res = await axios.post("/auth/token", params);
  const now = Date.now();
  const user = { ...res.data, expireDate: new Date(now + res.data.expiresIn * 1000), refreshTokenExpireDate: new Date(now + res.data.refreshTokenExpiresIn * 1000) };
  resolves.forEach(token => token(user));
  resolves = [];
  isRunning = false;
  return user;
}

export const HttpClient: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <UserContext>
      <HttpClientInner>{children}</HttpClientInner>
    </UserContext>
  );
}