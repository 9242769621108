import { DndContext, DragOverlay, TouchSensor, UniqueIdentifier, closestCenter, defaultDropAnimationSideEffects, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { FC, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { OverlaySortableItem } from "./OverlaySortableItem";
import { OverlaySortableSource } from "./OverlaySortableSource";

export type OverlaySortableProps<T extends { id: UniqueIdentifier }> = {
  defaultItems: T[];
  onChange?: (items: T[], ids: { activeId: UniqueIdentifier, overId: UniqueIdentifier }) => void | Promise<void>;
  SortableItem?: FC<{ item: T }>;
};

export const OverlaySortable = <T extends { id: UniqueIdentifier }>({ defaultItems, onChange, SortableItem }: OverlaySortableProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>();
  const sensors = useSensors(useSensor(TouchSensor));

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  const activeItem = items.find((item) => item.id === activeId);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={isMobile ? sensors : undefined}
      onDragStart={(event) => {
        setActiveId(event.active.id as string);
      }}
      onDragEnd={(event) => {
        setActiveId(undefined);
        const { active, over } = event;
        if (over == null || active.id === over.id) {
          return;
        }
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        setItems(newItems);
        if (onChange) {
          (async () => {
            const ret = onChange([...newItems], { activeId: active.id, overId: over.id });
            if (ret instanceof Promise) {
              await ret;
            }
          })().catch(err => {
            console.error(err);
          })
        }
      }}
    >
      <SortableContext items={items}>
        <ListGroup>
          {items.map((item) => (
            <OverlaySortableItem
              key={item.id}
              item={item}
              SortableItem={SortableItem || (({ item }) => <>{JSON.stringify(item)}</>)}
            />
          ))}
        </ListGroup>
      </SortableContext>
      <DragOverlay
        dropAnimation={{
          sideEffects: defaultDropAnimationSideEffects({
            styles: {}
          })
        }}
      >
        {activeItem && <OverlaySortableSource item={activeItem} className="border bg-white" SortableItem={SortableItem || (({ item }) => <>{JSON.stringify(item)}</>)} />}
      </DragOverlay>
    </DndContext >
  );
}