import { FC } from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";

import { HttpClient } from "../http-client/HttpClient";
import Header from "../organisms/header/Header";
import About from "../pages/about/About";
import ModifySet from "../pages/research/ModifySet";
import ModifySetDetail from "../pages/research/ModifySetDetail";
import Research from "../pages/research/Research";
import { ResearchRoot } from "../pages/research/ResearchList";
import Signin from "../pages/signin/Signin";
import SigninCallback from "../pages/signin/SigninCallback";
import RouteAuthGuard from "../route-auth-guard/RouteAuthGuard";
import { TransRouterRoot } from "../trans-router/TransRouterRoot";

const Root: FC = () => {
  return (
    <HttpClient>
      <Outlet />
    </HttpClient>
  );
}

const AuthRoot: FC = () => {
  return (
    <RouteAuthGuard>
      <ResearchRoot>
        <TransRouterRoot Header={Header} />
      </ResearchRoot>
    </RouteAuthGuard>
  );
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <div><h2>NotFound</h2><h3>404</h3></div>,
    children: [
      {
        path: "",
        element: <AuthRoot />,
        children: [
          {
            path: "",
            element: <Research />
          },
          {
            path: "modify-set",
            element: <ModifySet />
          },
          {
            path: "modify-set/:source/:id",
            element: <ModifySetDetail />
          },
          {
            path: "about",
            element: <About />
          },
        ]
      },
      {
        path: "signin",
        element: <Signin />
      },
      {
        path: "signin/callback",
        element: <SigninCallback />
      }
    ]
  }
]);