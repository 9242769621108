import { Target, motion } from "framer-motion";
import { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useTransRouterContext } from "./TransRouterRoot";

export const TransRouterMotionSection: FC<{ children: ReactNode; className?: string; onBack?: () => string | Promise<string>; }> = ({ children, className, onBack }) => {
  const { pathname, state } = useLocation();
  const { setTransition, isTransition, setOnBack } = useTransRouterContext();

  useEffect(() => {
    setOnBack(() => onBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transition = (() => {
    if (!state || !state.previousPath) {
      return "NORMAL";
    }
    return pathname.split("/").filter(a => !!a).length < (state.previousPath as string).split("/").filter(a => !!a).length ? "BACK" : "FORWARD";
  })();

  let initial: Target = { opacity: 0 };
  let animate: Target = { zIndex: 1, opacity: 1 };
  let exit: Target = { zIndex: 0, opacity: 0 };
  if (transition === "BACK") {
    initial = { opacity: 0, x: "-60%" };
    animate = { zIndex: 1, opacity: 1, x: 0 };
    exit = { zIndex: 0, opacity: 0, x: "60%" };
  }
  if (transition === "FORWARD") {
    initial = { opacity: 0, x: "60%" };
    animate = { zIndex: 1, opacity: 1, x: 0 };
    exit = { zIndex: 0, opacity: 0, x: "-60%" };
  }

  const classNames = ["TransRouter__MotionSection"];
  if (className) {
    classNames.push(...className.split(" "));
  }
  if (!isTransition) {
    classNames.push("aaaa");
  }

  return (
    <motion.section
      className={classNames.join(" ")}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{ duration: 0.3 }}
      // onAnimationStart={() => {
      //   // setTransition(true);
      // }}
      onAnimationComplete={() => {
        setTransition(false);
      }}
    >
      {children}
    </motion.section>
  );
}