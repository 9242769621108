import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Token, User, useUser } from "../../user/User";
import { redirectWithFunc } from "../../utils/redirect";
import { sleep } from "../../utils/sleep";

const SigninCallback: FC = () => {
  const refFirstRef = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      if (!refFirstRef.current) {
        return;
      }
      refFirstRef.current = false;
    }
    (async () => {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      if (!code || !state) {
        setHasError(true);
        return;
      }
      setSearchParams({});
      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("code", code);
      params.append("state", state);
      const res = await axios.post<Token>(`/auth/token`, params);
      const now = Date.now();
      const user: User = { ...res.data, expireDate: new Date(now + res.data.expiresIn * 1000), refreshTokenExpireDate: new Date(now + res.data.refreshTokenExpiresIn * 1000) };
      setUser(user);

      // NOTE: 少し待ってから遷移しないとユーザーが取れないためかガードに引っ掛かってしまうようなのでちょっとだけ待つ
      await sleep(500);
      navigate("/");
    })().catch(err => {
      setHasError(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasError) {
    return null;
  }
  return (
    <Container>
      <h2>ログインエラー</h2>
      <Alert variant="danger">
        エラーが発生しました。
      </Alert>
      <Button onClick={() => {
        redirectWithFunc(async () => {
          const { data: { gotoUrl } } = await axios.get<{ gotoUrl: string; }>("/auth/logout");
          return gotoUrl;
        });
      }}>再度ログインする</Button>
    </Container>
  );
}

export default SigninCallback;