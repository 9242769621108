let alreadyRedirect = false;
export function redirect(url: string) {
  if (alreadyRedirect) {
    return;
  }
  alreadyRedirect = true;
  window.location.href = url;
}
export function redirectWithFunc(func: () => string | Promise<string>) {
  if (alreadyRedirect) {
    return;
  }
  alreadyRedirect = true;
  const ret = func();
  if (ret instanceof Promise) {
    ret.then((url) => {
      window.location.href = url;
    }).catch((err) => {
      console.error(err);
    });
  } else {
    window.location.href = ret;
  }
}
export function isAlreadyRedirect() {
  return alreadyRedirect;
}