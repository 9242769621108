import { faChevronLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { Button, Nav, NavDropdown, Navbar } from "react-bootstrap";

import { useTransRouterNavigate } from "../../trans-router/TransRouterNavigate";
import { useUser } from "../../user/User";

import "./Header.scss";

const Header: FC<{ onBack?: () => void; }> = ({ onBack }) => {
  const { user } = useUser();
  const [expanded, setExpanded] = useState(false);
  const { navigate } = useTransRouterNavigate();

  const handleClick = async () => {
    if (!onBack) {
      return;
    }
    onBack();
  }

  return (
    <Navbar expand="lg" bg="light" fixed="top" expanded={expanded} className="Header">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        exit={{ opacity: 0 }}
        className="container"
      >
        {onBack ? (
          <div className="d-flex align-items-center">
            <Button size="sm" variant="back" className="me-1" onClick={handleClick}><FontAwesomeIcon icon={faChevronLeft} /></Button>
            <Navbar.Brand onClick={handleClick}>CigarMarketResearch</Navbar.Brand>
          </div>
        ) : (
          <>
            <Navbar.Brand onClick={() => { navigate("/"); setExpanded(false); }}>CigarMarketResearch</Navbar.Brand>
            <Navbar.Toggle onClick={() => { setExpanded(!expanded); }} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => { navigate("/"); setExpanded(false); }}>商品価格一覧</Nav.Link>
                <Nav.Link onClick={() => { navigate("/about"); setExpanded(false); }}>本アプリについて</Nav.Link>
              </Nav>
              <Nav>
                <NavDropdown className="nav-dropdown-end" title={<><FontAwesomeIcon icon={faUser} className="me-1" />{user?.payload.name}</>} id="collasible-nav-dropdown">
                  {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item><NavDropdown.Divider /> */}
                  <NavDropdown.Item onClick={async () => {
                    localStorage.removeItem("user");
                    const res = await axios.get<{ gotoUrl: string; }>(`/auth/logout${user?.refreshToken ? "?refreshToken=" + user.refreshToken : ""}`);
                    window.location.href = res.data.gotoUrl;
                    setExpanded(false);
                  }}>ログアウト</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </motion.div>
    </Navbar>
  );
}

export default Header;