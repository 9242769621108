import { FC } from "react";
import { Button, Modal } from "react-bootstrap";

import "./ConfirmDeleteModal.scss";

export const ConfirmDeleteModal: FC<{ showConfirmModal: boolean; handleClose: () => void; handleDone: () => void }> = ({ showConfirmModal, handleClose, handleDone }) => {
  return (
    <Modal show={showConfirmModal} size="sm" centered backdropClassName="confirm-backdrop" className="confirm">
      <Modal.Header>ほんとに削除しますか？</Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center gap-3">
          <Button onClick={handleClose} variant="secondary">いいえ</Button>
          <Button onClick={handleDone} variant="danger">はい</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}