import axios from "axios";
import { FC, useEffect } from "react";
import { redirectWithFunc } from "../../utils/redirect";

const Signin: FC = () => {
  useEffect(() => {
    (async () => {
      redirectWithFunc(async () => {
        const { data: { gotoUrl } } = await axios.get<{ gotoUrl: string; }>("/auth/authorize");
        return gotoUrl;
      });
    })().catch(err => {
      console.error(err);
    })
  }, []);
  return null;
};

export default Signin;