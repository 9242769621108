import { FC, ReactNode, createContext, useContext, useState } from "react";

export type Token = {
  token: string;
  payload: {
    id: string;
    name: string;
    email: string;
  };
  expiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
};
export type User = Token & {
  expireDate: Date;
  refreshTokenExpireDate: Date;
}

const UserContextInternal = createContext<{ user?: User; setUser: (user?: User) => void; }>({
  user: { token: "", payload: { id: "", name: "テスト ユーザー", email: "" }, expireDate: new Date(), expiresIn: 0, refreshToken: "", refreshTokenExpiresIn: 0, refreshTokenExpireDate: new Date() },
  setUser: (_) => { }
});

export function useUser() {
  return useContext(UserContextInternal);
}

export const UserContext: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(getLocalStorageUser());
  return (
    <UserContextInternal.Provider value={{
      user,
      setUser: (user) => {
        _user = user;
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
      }
    }}>{children}</UserContextInternal.Provider>
  );
}

let _user: User | undefined;

export function getUser() {
  return _user;
}

function getLocalStorageUser() {
  const userStr = localStorage.getItem("user");
  if (!userStr) {
    return undefined;
  }
  const tmpUser: User = JSON.parse(userStr);
  _user = {
    ...tmpUser,
    expireDate: new Date(tmpUser.expireDate),
    refreshTokenExpireDate: new Date(tmpUser.refreshTokenExpireDate)
  } as User;
  return _user;
}