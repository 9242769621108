import { faCheck, faList, faPenToSquare, faPlus, faTrash, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { Alert, Button, Container, Form, InputGroup, ListGroup, Spinner } from "react-bootstrap";

import { CurrencyCode } from "../../def";
import { useHttpClient } from "../../http-client/HttpClient";
import SourceSelect from "../../molecules/SourceSelect";
import { OverlaySortable } from "../../overlay-sortable";
import { TransRouterMotionSection } from "../../trans-router/TransRouterMotionSection";
import { useTransRouterNavigate } from "../../trans-router/TransRouterNavigate";
import { useUser } from "../../user/User";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { useResearchRootContext } from "./ResearchList";

import "./ModifySet.scss";

export type TrendSet = {
  id: string;
  name: string;
  products: { name: string; currencyCode: CurrencyCode }[];
  source: string;
  userId: string;
  updateOn: string;
}

const useTrendSet = (source: string, update: number) => {
  const [trendSets, setTrendSets] = useState<TrendSet[]>([]);
  const [loaded, setLoaded] = useState(false);
  const { httpClient } = useHttpClient();
  const { user } = useUser();
  useEffect(() => {
    if (!user) {
      return;
    }
    (async () => {
      const { data } = await httpClient.get<TrendSet[]>(`/api/v2/trend-sets/${user.payload.id}/${source}`);
      setTrendSets(data);
      setLoaded(true);
    })().catch(err => {
      console.error(err);
    });
  }, [user, httpClient, source, update]);
  return { trendSets, loaded };
}

const ModifySet: FC = () => {
  const { navigateIndent } = useTransRouterNavigate();
  const { httpClient } = useHttpClient();
  const { source, setSource } = useResearchRootContext();
  const [update, setUpdate] = useState(0);
  const [editTargetId, setEditTargetId] = useState<string | undefined>(undefined);
  const [confirmDeleteTarget, setConfirmDeleteTarget] = useState<string | undefined>(undefined);
  const [trendName, setTrendName] = useState("");
  const { trendSets, loaded } = useTrendSet(source, update);
  const { user } = useUser();

  const hasZeroProductSet = trendSets.filter(set => set.products.length === 0).length !== 0;

  return (
    <>
      <TransRouterMotionSection className="ModifySet" onBack={() => "/"}>
        <Container>
          <h2 className="h5 mb-3">価格推移セット編集</h2>
          <div className="d-flex justify-content-end mb-2">
            <div className="d-flex">
              <div>
                <SourceSelect
                  className="mb-1"
                  value={source}
                  disabled={!!editTargetId}
                  onChange={async (value) => {
                    setSource(value);
                  }} />
              </div>
            </div>
          </div>
          {hasZeroProductSet && (
            <Alert variant="warning">
              <Alert.Heading><FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />警告</Alert.Heading>
              <p>商品が選択されてない価格推移セットが存在します。<br />商品がない推移セットは価格推移の選択リストには表示されません。</p>
            </Alert>
          )}
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant="secondary"
              size="sm"
              disabled={!!editTargetId || trendSets.length >= 10}
              onClick={async () => {
                await httpClient.post(`/api/v2/trend-sets/${user?.payload.id}/${source}`, {
                  name: `リスト${trendSets.length + 1}`
                });
                setUpdate(update => update + 1);
              }}
              className="btn-icon"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          {!loaded && <div className="text-center"><Spinner animation="border" variant="secondary" /></div>}
          {loaded && trendSets.length === 0 && <div className="text-center text-muted">右上の<FontAwesomeIcon icon={faPlus} className="ms-1 me-1" />ボタンでセットを追加してください。</div>}
          {!editTargetId ? (
            <OverlaySortable
              defaultItems={trendSets}
              onChange={async (_item, { activeId, overId }) => {
                await httpClient.put(`/api/v2/trend-sets/${user?.payload.id}/${source}/${activeId}/_order`, {
                  overId
                });
                setUpdate(update => update + 1);
              }}
              SortableItem={({ item }) => (
                <div className="d-flex justify-content-between gap-4">{
                  <>
                    <div className="flex-grow-1 align-self-center text-break position-relative">
                      {item.name}
                      <span className={clsx("position-absolute", "top-0", "start-100", "translate-top-end", "badge", "rounded-pill", "opacity-75",
                        item.products.length === 0 ? "bg-warning" : "bg-secondary"
                      )}>
                        {item.products.length}
                      </span>
                    </div>
                    <div className="align-self-center d-flex">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-1"
                        onClick={async () => {
                          setEditTargetId(item.id);
                          setTrendName(item.name);
                        }}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-1"
                        onClick={() => {
                          navigateIndent(`/modify-set/${source}/${item.id}`)
                        }}>
                        <FontAwesomeIcon icon={faList} />
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setConfirmDeleteTarget(item.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </>
                  // )
                }
                </div>
              )} />
          ) : (
            <ListGroup>
              {trendSets.map(set => (
                <ListGroup.Item key={set.id}>
                  <div className="d-flex justify-content-between">
                    {
                      editTargetId === set.id ? (
                        <>
                          <Form onSubmit={async (e) => {
                            e.preventDefault();
                            if (!trendName) {
                              setEditTargetId(undefined);
                              setTrendName("");
                              return;
                            }
                            await httpClient.put(`/api/v2/trend-sets/${user?.payload.id}/${source}/${set.id}`, {
                              name: trendName
                            });
                            setEditTargetId(undefined);
                            setTrendName("");
                            setUpdate(update => update + 1);
                          }}
                            className="w-100"
                            noValidate>
                            <InputGroup>
                              <Form.Control value={trendName} onChange={(e) => setTrendName(e.target.value)} />
                              <Button variant="outline-secondary" onClick={() => {
                                setEditTargetId(undefined);
                                setTrendName("");
                              }}><FontAwesomeIcon icon={faXmark} /></Button>
                              <Button type="submit" variant="outline-secondary">
                                <FontAwesomeIcon icon={faCheck} />
                              </Button>
                            </InputGroup>
                          </Form>
                        </>
                      ) : (
                        <>
                          <div className="flex-grow-1 align-self-center">
                            {set.name}
                          </div>
                          <div className="align-self-center">
                            <Button
                              variant="secondary"
                              size="sm"
                              className="me-1"
                              disabled
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              className="me-1"
                              disabled
                            >
                              <FontAwesomeIcon icon={faList} />
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              disabled
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        </>
                      )
                    }
                  </div></ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Container>
      </TransRouterMotionSection>
      <ConfirmDeleteModal showConfirmModal={!!confirmDeleteTarget} handleClose={() => { setConfirmDeleteTarget(undefined); }} handleDone={() => {
        (async () => {
          await httpClient.delete(`/api/v2/trend-sets/${user?.payload.id}/${source}/${confirmDeleteTarget}`);
          setUpdate(update => update + 1);
          setConfirmDeleteTarget(undefined);
        })().catch(err => {
          console.error(err);
        });
      }} />
    </>
  );
};

export default ModifySet;